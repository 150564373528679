import { PrismaClient } from "@prisma/client";
import Axios from "axios";
import { CoreHelpers, CoreInit } from "./models";

let isInitialized = false;
let coreHelpers: CoreHelpers;
let coreInit: CoreInit;
let clientUnknownState: PrismaClient | null = null;

export async function initOllieCore(p: CoreInit) {
  if (isInitialized) {
    return;
  }

  coreInit = p;
  coreHelpers = new CoreHelpers(p);
  isInitialized = true;
}

export async function setOllieCoreFirestoreLiftDisabledStatus(status: boolean) {
  coreHelpers.getUniversalHelpers().ollieFirestoreV2._setFirestoreLiftDisabledStatus(status);
}

export function getServerHelpers() {
  if (!coreHelpers) {
    throw new Error("Run initOllieCore before you call getServerHelpers");
  }
  return coreHelpers.getServerHelpers();
}

export function getUniversalHelpers() {
  if (!coreHelpers) {
    throw new Error("Run initOllieCore before you call getServerHelpers");
  }
  return coreHelpers.getUniversalHelpers();
}

export function getFlexPrismaClient() {
  if (!clientUnknownState) {
    clientUnknownState = new PrismaClient();
  }
  return clientUnknownState;
}

export function isServerEnv() {
  return !!coreInit.serverInit;
}

// https://dev.to/benlesh/bigint-and-json-stringify-json-parse-2m8p
export function bigIntToObject<T>(data: T): T {
  return JSON.parse(JSON.stringify(data, (key, value) => (typeof value === "bigint" ? value.toString() + "n" : value)));
}

export function sendFlexRequest(fnName: string, body: any) {
  const { serverConfig } = getServerHelpers();
  return Axios.post(serverConfig.httpFlexApiRoot + "/api-bifrost/" + fnName, body, {
    headers: { "x-magic-token": "LY:82vV$~uhk<N^" }
  });
}

// i18n certified - complete
